import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Group, Quaternion } from 'three'
import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'
import { Reflector } from 'three/examples/jsm/objects/Reflector'
import gsap, { Tween } from 'gsap/gsap-core'
import { CustomEase } from 'gsap/all'
import { MathUtils } from 'three'
import { Text } from 'troika-three-text'

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//Base Groups
var baseScreen = new THREE.Group()
var screenRound = new THREE.Group()

const color01 = '#007880'
const color02 = '#6B778D'
const color03 = '#EEEEEE'
const color04 = '#000000'

/**
 * Models
 */

const loadingManager = new THREE.LoadingManager();

loadingManager.onLoad = function ( ) {

	const loadingScreen = document.getElementById( 'loading-screen' );
    
    loadingScreen.classList.add( 'fade-out' );

    loadingScreen.style.pointerEvents = "none"

};

const gltfLoader = new GLTFLoader(loadingManager)


//Spawn Screens
for (var i = 0; i < 8; i++) {
    let str = 'video_0'
    let index = i
    let video = document.getElementById(str.concat(index + 1))
    video.play()

    let videoTexture = new THREE.VideoTexture(video)
    videoTexture.center.set(0.5, 0.5)
    videoTexture.rotation = (Math.PI / 180) * -90
    videoTexture.scale = -1
    videoTexture.repeat.set(-1, 1.9)
    videoTexture.offset.set(0, 0.45)

    let videoMaterial = new THREE.MeshStandardMaterial({
        map: videoTexture,
        side: THREE.DoubleSide,
        emissive: 0xffffff,
        emissiveMap: videoTexture
    })
    videoMaterial.emissiveIntensity = 1

    gltfLoader.load(
        './model/ScreenMesh.gltf',
        (gltf) => {
            let screenObj = gltf.scene.children[0]
            screenObj.material = videoMaterial
            screenObj.rotation.y = ((Math.PI / 180) * 45) * index
            screenObj.position.set(0, 1.5, 0)
            screenObj.translateZ(-8)
            screenObj.castShadow = true
            screenRound.add(screenObj)
        }
    )
}

scene.add(screenRound)


/**
 * Fonts
 */
const fontLoader = new THREE.FontLoader(loadingManager)
const textGroup = new THREE.Group()

const textMaterial = new THREE.MeshStandardMaterial({
    emissive: color01,
    emissiveIntensity: 1
})

fontLoader.load(
    './fonts/King Richard_Regular.json',
    (font) => {
        const Name = new THREE.TextGeometry(
            'Italo',
            {
                font: font,
                size: 3,
                height: 0.5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 2
            }
        )
        const LastName = new THREE.TextGeometry(
            'Pardave Navarro',
            {
                font: font,
                size: 1.75,
                height: 0.5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 2
            }
        )

        const Title = new THREE.TextGeometry(
            'Technical Designer',
            {
                font: font,
                size: 1.25,
                height: 0.5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 2
            }
        )
        Name.computeBoundingBox()
        LastName.computeBoundingBox()
        Title.computeBoundingBox()

        Title.center()
        Name.center()
        LastName.center()

        Name.translate(0, 8, 0)
        LastName.translate(0, 4.5, 0)
        Title.translate(0, 1, 0)

        const text01 = new THREE.Mesh(Name, textMaterial)
        const text02 = new THREE.Mesh(LastName, textMaterial)
        const text03 = new THREE.Mesh(Title, textMaterial)
        textGroup.add(text01)
        textGroup.add(text02)
        textGroup.add(text03)
    }
)

const divider = new THREE.Mesh(
    new THREE.BoxGeometry(20, 0.25, 0.25),
    textMaterial
)
divider.position.set(0, 2.8, 0)
textGroup.add(divider)

scene.add(textGroup)
textGroup.position.set(0, 4, 0)
textGroup.scale.set(0.85, 0.85, 0.85)
textGroup.rotateY(Math.PI)


/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(100, 100),
    new THREE.MeshStandardMaterial({
        color: color02,
        metalness: 0,
        roughness: 1,
        transparent: true,
        opacity: 0.95
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)

const mirror01 = new Reflector(
    new THREE.PlaneGeometry(100, 100),
    {
        color: new THREE.Color(color02),
        textureWidth: window.innerWidth * window.devicePixelRatio,
        textureHeight: window.innerHeight * window.devicePixelRatio
    }
)
mirror01.rotation.x = - Math.PI * 0.5
mirror01.position.y = -0.01
scene.add(mirror01)

/**
 * Fog
 */
const fog = new THREE.Fog(color04, 30, 50)
scene.fog = fog

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(color03, 0.1)
scene.add(ambientLight)


const centerPointLight = new THREE.PointLight(color03, 1, 20)
centerPointLight.position.set(0, 3, 0)
centerPointLight.castShadow = true
centerPointLight.shadow.mapSize.width = 256
centerPointLight.shadow.mapSize.height = 256
centerPointLight.shadow.camera.far = 50
scene.add(centerPointLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const fov = 35;
const planeAspectRatio = 16 / 9;

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    cameraOverscan()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


function cameraOverscan() {
    camera.aspect = sizes.width / sizes.height

    if (camera.aspect > planeAspectRatio) {
        // window too large
        camera.fov = fov;
    } else {
        // window too narrow
        const cameraHeight = Math.tan(MathUtils.degToRad(fov / 2));
        const ratio = camera.aspect / planeAspectRatio;
        const newCameraHeight = cameraHeight / ratio;
        camera.fov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
    }

    camera.updateProjectionMatrix()
}
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 2, -25)
cameraOverscan()
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
const targetPosition = new THREE.Vector3(0, 6, 0)
controls.target.set(targetPosition.x, targetPosition.y, targetPosition.z)
controls.enablePan = false
controls.enableZoom = false
controls.enableRotate = false
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(color04)

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const cursor = {
    x: 0,
    y: 0
}

var allowWiggle = true

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

document.body.addEventListener('mouseout', function(e) {
    if (!e.relatedTarget && !e.toElement) {
        cursor.x = 0
        cursor.y = 0
    }
});

var scrollDelay = 500
var lastScroll = Date.now() - scrollDelay
var isMouseInDescriptiton = false
const rotateAnim = new THREE.Clock(false)


window.addEventListener("wheel", function (e) {
    if(isMouseInDescriptiton)return
    if (Date.now() - scrollDelay < lastScroll) return
    lastScroll = Date.now()
    let sig = e.deltaY > 0 ? 1 : -1
    rotateVideos(sig)
}, true);

let touchendX = 0
let touchstartX = 0

function handleGesture() {
    if(isMouseInDescriptiton){
        isMouseInDescriptiton = false
        return
    }
    if (Math.abs(touchendX - touchstartX) > 100) {
        if (Date.now() - scrollDelay < lastScroll) return
        lastScroll = Date.now()
        if (touchendX < touchstartX) rotateVideos(-1)
        if (touchendX > touchstartX) rotateVideos(1)
    }
}

document.addEventListener('pointerdown', function (e) {
    touchstartX = e.x
})

document.addEventListener('pointerup', function (e) {
    touchendX = e.x
    handleGesture()
})

document.addEventListener('touchstart', function (e) {
    touchstartX = e.changedTouches[0].screenX
})

document.addEventListener('touchend', function (e) {
    touchendX = e.changedTouches[0].screenX
    handleGesture()
})

document.getElementById("home_btn").addEventListener("click", function (e) {
    homeMoveTo()
})
document.getElementById("homeIcon").addEventListener("click", function (e) {
    homeMoveTo()
})

document.getElementById("myDescContainter").addEventListener("mouseenter", function (e) {
    isMouseInDescriptiton = true
})

document.getElementById("myDescContainter").addEventListener("pointerdown", function (e) {
    isMouseInDescriptiton = true
})

document.getElementById("myDescContainter").addEventListener("mouseleave", function (e) {
    isMouseInDescriptiton = false
})


document.getElementById("projects_btn").addEventListener("click", function (e) {
    projectMoveTo()
})
document.getElementById("projects_btn2").addEventListener("click", function (e) {
    projectMoveTo()
})

document.getElementById("about_btn").addEventListener("click", function (e) {
    aboutMoveTo()
})

function homeMoveTo(){
    moveCamera(new THREE.Vector3(0, 2, -25), new THREE.Vector3(0, 6, 0))
    moveTitle(new THREE.Vector3(0, 4, 0))
    SetVideoTextVisibility(false)
    isViewingProjects = false
    $("#projects_btn2").removeClass('invisible')
    $("#myAboutContainter").addClass('invisible')
    $('.navbar-nav').find('.active').removeClass('active');
    $("#home_btn").addClass('active')
}

function projectMoveTo() {
    moveCamera(new THREE.Vector3(0, 2, -15), new THREE.Vector3(0, 1, 0))
    moveTitle(new THREE.Vector3(0, 25, 0))
    SetVideoTextVisibility(true)
    isViewingProjects = true
    gameTitleText.material.opacity = 1
    $("#projects_btn2").addClass('invisible')
    $('.navbar-nav').find('.active').removeClass('active');
    $("#projects_btn").addClass('active')
    $("#myAboutContainter").addClass('invisible')
}

function aboutMoveTo() {
    moveCamera(new THREE.Vector3(0, 10, -30), new THREE.Vector3(0, 3, 0))
    moveTitle(new THREE.Vector3(0, 1, 0))
    SetVideoTextVisibility(false)
    isViewingProjects = false
    gameTitleText.material.opacity = 1
    $("#projects_btn2").addClass('invisible')
    $("#myAboutContainter").removeClass('invisible')
}

var currentVideoId = 0
var isViewingProjects = false
var pageIsLoaded = false

//#region Video Text Array
var TitlesArray = ['XDefiant', 'Iron Horse Raid',
    'Warlords of New York', 'The Division 2',
    'Ghostway', 'Shore\'s Den', 'DRail', 'Kitchen\'s Wrath']
var ProjectArray = ['Ubisoft / Snowdrop', 'Ubisoft / Snowdrop',
    'Ubisoft / Snowdrop', 'Ubisoft / Snowdrop',
    'School Project / Unity', 'School Project / UDK3', 'School Project / Unity', 'School Project / Unity']
var RolesArray = ['Technical Designer', 'Technical Level Designer',
    'Junior Technical Level Designer', 'Technical Level Designer Intern',
    'Gameplay Programmer', 'Level Designer', 'Gameplay Programmer', 'Gameplay Programmer and Game Designer']
var DescriptionArray = ['This being my first FPS multiplayer gave me a huge insight into complex networking and gameplay systems. I have been working on design, prototyping and implementations of gameplay mechanics. My main work has been focused on Ultras, Skills and Gadgets. This project has pushed me into being more proactive and find ways to push the current technical constraints in order to develop the best experience possible',
 'Worked on a small team in order to release the second Division 2 Raid. I was responsible of scripting the entirety of the first and last fight, while still supporting other moments of the Raid. I helped in finding new solutions to create more impactful moments within our technical constraints. Helped on prototyping various fights and ideas from the world team early into development. Improved the speed and reaction of my work in order to iterate based on the team feedback.',
 'During this Division 2 expansion I worked on two of the main story lines mission. I supported in scripting the mission’s logic, validating other scripting. Helped in creating mission elements that would enable a more interactive experience with player, from props to dynamic moving objects in the environment. Helped supporting other teams from other studios in sharing knowledge of other subsystems of the engine.',
 'I worked as an intern during the live phase of Division 2. I worked by supporting the world team in scripting and set up of different gameplay props and in world actions. Learned to use the Snowdrop engine and gained proficiency working on node scripting.',
 'This project was a continuation from a previous puzzle game. This game is focused on speed and was designed with the intention of supporting speedruns. I worked on the 3C’s programming of the player, physics simulations and the different gameplay systems that would allow for a more varied gameplay. I learned to integrate the steam SDK into our Unity project. I did some technical art shaders for the environment, in order to support the artistic direction that had been chosen.',
 'We were tasked on developing a multiplayer CTF (Capture the flag) map in Unreal Tournament 3. Learned how to use UDK BSP brushes in order to create the general layout. Learned about iteration and data analysis based on gameplay statistics and feedback. Gave me a better insight into FPS multiplayer gameplay elements that exist.',
 'End of studies project. In this project I worked on some 3C’s of the vehicle and developing tools for the procedural creation of tracks for Level Designers based on a spline system. Worked on technical art, by creating gameplay shaders, the volumetric lighting system and UI element such as mini-map rendering. Worked on AI behavior for enemies both scripted and an iteration on Unity ML-Agents, where they would be able to learn from the player past their original training.',
 'This was my first game during my university studies. This project allowed me to learn to develop modular gameplay features, and tools for other designers in order to quickly iterate on gameplay. I was in charge of Character 3C Programming, and giving LD tools to designers. It helped me understand local multiplayer constraints and development. Created a basic progression system with unlockable skins. It was presented at the 2017 Japan Touch in Lyon, France']

 //#endregion

window.onload = function () {
    var myRole = document.getElementById("myRole")
    var myDescription = document.getElementById("myDescription")
    var myTitle = document.getElementById("myTitle")
    pageIsLoaded = true
}

var targetAngle = screenRound.rotation.y

function rotateVideos(sig, override = false, durationOverride = 0.5) {
    if (isViewingProjects || override) {
        currentVideoId = (((currentVideoId - sig) % 8 + 8) % 8)
        targetAngle = targetAngle + ((Math.PI / 180) * 45 * sig)
        var tweenRotation = Tween.to(screenRound.rotation, {
            y: targetAngle,
            duration: durationOverride,
            ease: 'back.inOut'
        }).play()
        
        UpdateVideoText()
    }
}

function moveTitle(posTarget) {
    var tweenTitlePos = Tween.to(textGroup.position, {
        x: posTarget.x,
        y: posTarget.y,
        z: posTarget.z,
        duration: 1,
        ease: 'power1.out'
    }).play()
}


function moveCamera(posTarget, viewTarget) {
    allowWiggle = false
    var tweenViewControl = Tween.to(controls.target, {
        x: viewTarget.x,
        y: viewTarget.y,
        z: viewTarget.z,
        duration: 1,
        ease: 'power1.out'
    }).play()
    var tweenViewTarget = Tween.to(targetPosition, {
        x: viewTarget.x,
        y: viewTarget.y,
        z: viewTarget.z,
        duration: 1,
        ease: 'power1.out'
    }).play()
    var tweenPosCam = Tween.to(camera.position, {
        x: posTarget.x,
        y: posTarget.y,
        z: posTarget.z,
        duration: 1,
        ease: 'power1.out'
    }).play()
    setTimeout(function () { allowWiggle = true }, 1000);
}

function UpdateVideoText() {
    setDescriptionContent()
}

function setDescriptionContent(){
    myTitle.innerHTML = TitlesArray[currentVideoId]
    myRole.innerHTML = "<b>Role: </b>" + RolesArray[currentVideoId] + "&emsp;<b>Project: </b>" + ProjectArray[currentVideoId] 
    myDescription.innerHTML = "<b>Description: </b>" + DescriptionArray[currentVideoId]
}

function SetVideoTextVisibility(display) {
    setDescriptionContent()
    if (display) {
        $("#myTitle").removeClass('invisible')
        $("#myDescContainter").removeClass('invisible')
    } else {
        $("#myTitle").addClass('invisible')
        $("#myDescContainter").addClass('invisible')
    }
}

var particularGruop = new THREE.Object3D();

function generateParticle(num, amp = 2) {
  var gmaterial = new THREE.MeshPhysicalMaterial({color:0xFFFFFF, emissive:0xFFFFFF, side:THREE.DoubleSide});

  var gparticular = new THREE.CircleGeometry(0.2,5);

  for (var i = 1; i < num; i++) {
    var pscale = 0.001+Math.abs(mathRandom(0.35));
    var particular = new THREE.Mesh(gparticular, gmaterial);
    particular.position.set(mathRandom(amp),mathRandom(18),mathRandom(amp));
    particular.rotation.set(mathRandom(),mathRandom(),mathRandom());
    particular.scale.set(pscale,pscale,pscale);
    particular.speedValue = 0.5+mathRandom(2);

    particularGruop.add(particular);
  }

  particularGruop.position.z = 10
}
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    generateParticle(750, 20);
}else{
    generateParticle(1250, 20);
}


scene.add(particularGruop);

function mathRandom(num = 1) {
  var setNumber = - Math.random() * num + Math.random() * num;
  return setNumber;
}


// Create:
const gameTitleText = new Text()
// Create:
const descriptionText = new Text()

var targetLerpedPosition = targetPosition

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime

    var autoRotateTime = 7

    var deltaMove = new THREE.Vector3()
    deltaMove.subVectors(camera.position, targetPosition)
    deltaMove = deltaMove.normalize()

    var targetVector = new THREE.Vector3(targetPosition.x+(cursor.x*deltaMove.z*2),targetPosition.y-(cursor.y*2),targetPosition.z+(cursor.x*deltaMove.x*2))

    if(allowWiggle){
        controls.target.lerp(targetVector, 0.025)
    }

    if (!isViewingProjects) {
        if((elapsedTime % autoRotateTime) < (previousTime % autoRotateTime) )rotateVideos(-1, true, 1)
    }

    for (var i = 0, l = particularGruop.children.length; i<l; i++) {
        var newObject = particularGruop.children[i];
        newObject.rotation.x += newObject.speedValue/10;
        newObject.rotation.y += newObject.speedValue/10;
        newObject.rotation.z += newObject.speedValue/10;
        //---
        //newObject.position.y = Math.sin(elapsedTime) * 0.001;
      };
      
      //---
      particularGruop.rotation.y += 0.0002;

    previousTime = elapsedTime

    // Update controls
    controls.update()
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()